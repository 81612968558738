import {GoogleApiAuthenticator, GoogleApisStatus} from "./GoogleApiAuthenticator";
import React from "react";
import "./Header.css";
import CalendarChooser from "./CalendarChooser";
import {Calendar} from "./Calendar";

export interface HeaderProps {
  status: GoogleApisStatus;
  authenticator: GoogleApiAuthenticator;
  calendars: Calendar[];
  persistAndSetCalendars: (updater: ((oldCalendars: Calendar[]) => Calendar[])) => void;
}

function Header(props: HeaderProps) {
  return <header className="Header">
    <div className="Header-auth">
      {props.status === GoogleApisStatus.INITIALIZED &&
          <button onClick={() => props.authenticator.authorize()}>Authorize</button>}
      {props.status === GoogleApisStatus.AUTHORIZED &&
          <button onClick={() => props.authenticator.signOut()}>Sign Out</button>}
    </div>
    <CalendarChooser calendars={props.calendars} setCalendars={props.persistAndSetCalendars}/>
  </header>;
}

export default Header;
