import dayjs from "dayjs";
import { Event } from "./Event";
import {getDayString} from "./utils";

export class Day {
  public readonly date: dayjs.Dayjs;
  public readonly key: string; // "YYYY-MM-DD"
  public readonly events: Event[] = [];
  // Index is slot value in Event.
  public readonly slots: (Event | undefined)[] = [];

  constructor(date: dayjs.Dayjs) {
    this.date = date;
    this.key = getDayString(date);
  }

  /**
   * Set this event in the specified slot.
   */
  public setSlot(slot: number, event: Event): void {
    while (this.slots.length <= slot) {
      this.slots.push(undefined);
    }
    this.slots[slot] = event;
  }

  /**
   * Find an empty slot for the event, set it, and return the slot number
   */
  public allocateSlot(event: Event): number {
    let slot: number;
    for (slot = 0; slot < this.slots.length; slot++) {
      if (this.slots[slot] === undefined) {
        break;
      }
    }
    this.setSlot(slot, event);
    return slot;
  }
}
