
import {Event} from "./Event";

/**
 * Type of event that spends multiple days.
 */
export class MultiDayEvent extends Event {
  // Where to position multi-day events (0+).
  public slot = 0;
  // Number of braces between our brace and the label.
  public labelOffset = 0;
  // Top and bottom "pixel" of center of ends. This is approximate because we don't know the
  // height of each day.
  public topY = 0;
  public bottomY = 0;
  // Possible positions of the label, in pixels.
  public readonly possibleLabelY: number[] = []
  // Vertical position (0 to 1) of the label within the brace.
  public labelPosition = 0.5;
}
