import dayjs from "dayjs";
import {Event} from "./Event";
import {getCalendarModernColor} from "./colors";
import {MultiDayEvent} from "./MultiDayEvent";
import {TimeSpan} from "./TimeSpan";
import {clampDay} from "./utils";
import {colorToFilter} from "./colorToFilter";

export class Calendar {
  public readonly gcal: gapi.client.calendar.CalendarListEntry;
  public readonly cssFilter: string;
  public readonly events: Event[] = [];
  public visible = true;

  constructor(gcal: gapi.client.calendar.CalendarListEntry) {
    this.gcal = gcal;
    this.cssFilter = colorToFilter(this.getBackgroundColor());
  }

  /**
   * Creates an Event object (or subclass) from a Google Event object.
   */
  public makeEvent(gevent: gapi.client.calendar.Event,
                   minTime: dayjs.Dayjs, maxTime: dayjs.Dayjs): Event | MultiDayEvent {

    const isFullDay = gevent.start.dateTime === undefined;
    const startDate = isFullDay ? gevent.start.date : gevent.start.dateTime;
    const endDate = isFullDay ? gevent.end.date : gevent.end.dateTime;
    const timeSpan = new TimeSpan(
        clampDay(dayjs(startDate), minTime, maxTime),
        clampDay(dayjs(endDate), minTime, maxTime));

    if (timeSpan.isMultiDay()) {
      return new MultiDayEvent(gevent, this, isFullDay, timeSpan);
    } else {
      return new Event(gevent, this, isFullDay, timeSpan);
    }
  }

  /**
   * Fetch the events, filling the "events" field.
   *
   * @return whether successful
   */
  public async fetchEvents(minTime: dayjs.Dayjs, maxTime: dayjs.Dayjs) {
    let nextPageToken: string | undefined = undefined;

    do {
      let response: gapi.client.HttpRequestFulfilled<gapi.client.calendar.Events>;

      try {
        const request: gapi.client.calendar.EventsListParameters = {
          'calendarId': this.gcal.id,
          'timeMin': minTime.toISOString(),
          'timeMax': maxTime.toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'orderBy': 'startTime',
        };
        if (nextPageToken !== undefined) {
          request.pageToken = nextPageToken;
        }
        response = await gapi.client.calendar.events.list(request);
      } catch (err: any) {
        console.log("can't fetch calendar for " + this.gcal.id, err.result.error.message);
        return false;
      }

      const events = response.result.items.map(
          gevent => this.makeEvent(gevent, minTime, maxTime));
      this.events.push(...events);

      nextPageToken = response.result.nextPageToken;
    } while (nextPageToken !== undefined);

    return true;
  }

  public getBackgroundColor(): string {
    return getCalendarModernColor(this.gcal.backgroundColor ?? "#ffffff");
  }

  public getForegroundColor(): string {
    return "white";
  }

  /**
   * Get a CSS style for this calendar.
   */
  public getStyle(): {color: string, background: string} {
    return {
      color: this.getForegroundColor(),
      background: this.getBackgroundColor(),
    };
  }
}
