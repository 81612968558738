
const CLIENT_ID = '347396879496-trscvima4dhhie39uskuv8uncdg74inv.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCpNNxB9BJZE1KL-9DvpU5rlzQpHYxT8iw';

// Discovery doc URL for APIs used by the app.
const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

const STORAGE_TOKEN_KEY = "calendar-view-auth-token";

export enum GoogleApisStatus {
  INITIALIZING, INITIALIZED, AUTHORIZED,
}

/**
 * Handles the OAuth flow for Google Calendar authentication.
 */
export abstract class GoogleApiAuthenticator {
  private gapiInited = false;
  private gisInited = false;
  private tokenClient: google.accounts.oauth2.TokenClient | undefined = undefined;

  public abstract onStatusChange(status: GoogleApisStatus): void;

  /**
   * Load the scripts for the Google APIs. Only call this once.
   */
  public initialize() {
    this.onStatusChange(GoogleApisStatus.INITIALIZING);

    // Load the script that lets us access Google APIs, such as Calendar.
    const scriptGapi = document.createElement("script");
    scriptGapi.src = "https://apis.google.com/js/api.js";
    scriptGapi.async = true;
    scriptGapi.defer = true;
    scriptGapi.onload = () => this.initGapi();
    document.body.appendChild(scriptGapi);

    // Load the script that will let the user authorize us to get their data.
    const scriptGoogle = document.createElement("script");
    scriptGoogle.src = "https://accounts.google.com/gsi/client";
    scriptGoogle.async = true;
    scriptGoogle.defer = true;
    scriptGoogle.onload = () => this.initGis();
    document.body.appendChild(scriptGoogle);
  }

  private async initGapi() {
    // Load the "client" module of Gapi.
    gapi.load("client", () => this.initGapiClient());
  }

  private async initGapiClient() {
    // Register our API key.
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    this.gapiInited = true;
    this.maybeEnableButtons();
  }

  private initGis() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: async (resp) => {
        if (resp.error !== undefined) {
          throw resp;
        }
        const token = gapi.client.getToken();
        window.localStorage.setItem(STORAGE_TOKEN_KEY, token.access_token);

        this.onStatusChange(GoogleApisStatus.AUTHORIZED);
      },
    });
    this.gisInited = true;
    this.maybeEnableButtons();
  }

  private maybeEnableButtons() {
    if (this.gapiInited && this.gisInited) {
      const token = window.localStorage.getItem(STORAGE_TOKEN_KEY);
      if (token !== null) {
        // Not right, just because we have a token doesn't mean it's valid.
        gapi.client.setToken({access_token: token});
        this.onStatusChange(GoogleApisStatus.AUTHORIZED);
      } else {
        this.onStatusChange(GoogleApisStatus.INITIALIZED);
      }
    }
  }

  public authorize() {
    if (this.tokenClient === undefined) {
      throw new Error("must wait for library to be initialized");
    }

    /*
    const token = gapi.client.getToken();
    if (token === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      this.tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({prompt: ''});
    }*/
    this.tokenClient.requestAccessToken({
      prompt: "",
    });
  }

  public signOut() {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token, () => {});
      gapi.client.setToken(null);
      window.localStorage.removeItem(STORAGE_TOKEN_KEY);
      this.onStatusChange(GoogleApisStatus.INITIALIZED);
    }
  }
}
