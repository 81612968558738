import dayjs from "dayjs";

/**
 * Represents an immutable span of time.
 */
export class TimeSpan {
  /**
   * The start time, inclusive.
   */
  public readonly startTime: dayjs.Dayjs;
  /**
   * The end time, exclusive.
   */
  public readonly endTime: dayjs.Dayjs;

  constructor(startTime: dayjs.Dayjs, endTime: dayjs.Dayjs) {
    this.startTime = startTime;
    this.endTime = endTime;
  }

  /**
   * Whether this time span lasts more than one day.
   */
  public isMultiDay(): boolean {
    return this.endTime.isAfter(this.startTime.add(1, "day"));
  }

  /**
   * Returns the union of the two time spans.
   */
  public unionWith(other: TimeSpan): TimeSpan {
    return new TimeSpan(
        dayjs.min(this.startTime, other.startTime) as dayjs.Dayjs,
        dayjs.max(this.endTime, other.endTime) as dayjs.Dayjs);
  }

  /**
   * Whether the two time spans overlap.
   */
  public overlaps(other: TimeSpan): boolean {
    return this.startTime.isBefore(other.endTime) && this.endTime.isAfter(other.startTime);
  }

  /**
   * Generates the days in the time span.
   */
  public* days() {
    for (let time = this.startTime; time.isBefore(this.endTime); time = time.add(1, "day")) {
      yield time;
    }
  }
}
