import {Calendar} from "./Calendar";
import "./CalendarChooser.css";

export interface CalendarChooserProps {
  calendars: Calendar[];
  // setCalendars: ;
  setCalendars: (updater: ((oldCalendars: Calendar[]) => Calendar[])) => void;
}

function CalendarChooser(props: CalendarChooserProps) {
  function toggle(toggledCalendar: Calendar) {
    props.setCalendars(oldCalendars => {
      const newCalendars = [...oldCalendars];

      for (const calendar of newCalendars) {
        if (calendar.gcal.id === toggledCalendar.gcal.id) {
          calendar.visible = !calendar.visible;
        }
      }

      return newCalendars;
    });
  }

  // Sort the calendar list.
  const calendars = [...props.calendars];
  calendars.sort((a: Calendar, b: Calendar) => {
    // Sort by decreasing number of events, to put important calendars up front.
    let cmp = b.events.length - a.events.length;
    if (cmp !== 0) {
      return cmp;
    }

    // Fall back on name.
    const aDesc = a.gcal.summary ?? "";
    const bDesc = b.gcal.summary ?? "";
    cmp = aDesc.localeCompare(bDesc, undefined, {
      usage: "sort",
      sensitivity: "base",
    });
    if (cmp !== 0) {
      return cmp;
    }

    // Break ties with ID so that same-named calendars don't shift around.
    return a.gcal.id.localeCompare(b.gcal.id);
  });

  return <div className="CalendarChooser">
    {calendars.map(calendar => {
      const style = calendar.getStyle();
      if (!calendar.visible) {
        style.background = "#ccc";
      }
      const label = calendar.gcal.summary + (calendar.events.length === 0 ? "" : " (" + calendar.events.length + ")");
      return <div key={calendar.gcal.id} className="CalendarChooser-calendar">
        <label><input type="checkbox" checked={calendar.visible} onChange={() => toggle(calendar)}></input>
          <span className="CalendarChooser-name" style={style}>{label}</span></label>
      </div>;
    })}
  </div>;
}

export default CalendarChooser;
