import {Day} from "./Day";
import classNames from "classnames";
import EventView from "./EventView";
import "./DayView.css";
import isToday from "dayjs/plugin/isToday";
import dayOfYear from "dayjs/plugin/dayOfYear";
import minMax from "dayjs/plugin/minMax";
import dayjs from "dayjs";

dayjs.extend(isToday);
dayjs.extend(dayOfYear);
dayjs.extend(minMax);

export interface DayViewProps {
  day: Day;
}

function DayView(props: DayViewProps) {
  const dayOfWeek = props.day.date.day();
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
  const isToday = props.day.date.isToday();

  return <div className={classNames({
    DayView: true,
    "DayView-weekend": isWeekend,
    "DayView-today": isToday,
  })}
  style={{
    gridColumn: 3,
    gridRow: props.day.date.dayOfYear(),
  }}>
    <div className="DayView-dateOfMonth"><span>{props.day.date.date()}</span></div>
    <div className="DayView-events">{
      props.day.events.map((event, i) => <EventView key={event.calendar.gcal.id + " " + event.gevent.id} event={event} isFirst={i === 0}/>)
    }</div>
  </div>;
}

export default DayView;
