
import { Event } from "./Event";
import dayjs from "dayjs";
import "./EventView.css";

// Not necessary with the pills.
const SHOW_SEMICOLONS = false;

export interface EventViewProps {
  event: Event;
  isFirst: boolean;
}

function getTimestamp(date: dayjs.Dayjs) {
  if (date.minute() === 0) {
    return date.format("ha");
  } else {
    return date.format("h:mma");
  }
}

function EventView(props: EventViewProps) {
  return <>
    {!props.isFirst && SHOW_SEMICOLONS && <span className="EventView-separator">; </span>}
    <a className="EventView"
       href={props.event.gevent.htmlLink}
       target="_blank"
       style={props.event.calendar.getStyle()}
       rel="noreferrer">
      {props.event.isFullDay ? "" : <span className="EventView-timestamp">{getTimestamp(props.event.timeSpan.startTime)}</span>}
      <span className="EventView-summary">{props.event.gevent.summary}</span>
  </a>
  </>;
}

export default EventView;
