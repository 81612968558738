
// Index, name, classic, and modern colors for events.
// https://google-calendar-simple-api.readthedocs.io/en/latest/colors.html
const EVENT_COLORS = [
  [ "1", "Lavender", "#A4BDFC", "#7986CB" ],
  [ "2", "Sage", "#7AE7BF", "#33B679" ],
  [ "3", "Grape", "#DBADFF", "#8E24AA" ],
  [ "4", "Flamingo", "#FF887C", "#E67C73" ],
  [ "5", "Banana", "#FBD75B", "#F6BF26" ],
  [ "6", "Tangerine", "#FFB878", "#F4511E" ],
  [ "7", "Peacock", "#46D6DB", "#039BE5" ],
  [ "8", "Graphite", "#E1E1E1", "#616161" ],
  [ "9", "Blueberry", "#5484ED", "#3F51B5" ],
  [ "10", "Basil", "#51B749", "#0B8043" ],
  [ "11", "Tomato", "#DC2127", "#D50000" ],
];

// Index, name, classic, and modern colors for calendars.
// https://google-calendar-simple-api.readthedocs.io/en/latest/colors.html
const CALENDAR_COLORS = [
  [ "1", "Cocoa", "#AC725E", "#795548" ],
  [ "2", "Flamingo", "#D06B64", "#E67C73" ],
  [ "3", "Tomato", "#F83A22", "#D50000" ],
  [ "4", "Tangerine", "#FA573C", "#F4511E" ],
  [ "5", "Pumpkin", "#FF7537", "#EF6C00" ],
  [ "6", "Mango", "#FFAD46", "#F09300" ],
  [ "7", "Eucalyptus", "#42D692", "#009688" ],
  [ "8", "Basil", "#16A765", "#0B8043" ],
  [ "9", "Pistachio", "#7BD148", "#7CB342" ],
  [ "10", "Avocado", "#B3DC6C", "#C0CA33" ],
  [ "11", "Citron", "#FBE983", "#E4C441" ],
  [ "12", "Banana", "#FAD165", "#F6BF26" ],
  [ "13", "Sage", "#92E1C0", "#33B679" ],
  [ "14", "Peacock", "#9FE1E7", "#039BE5" ],
  [ "15", "Cobalt", "#9FC6E7", "#4285F4" ],
  [ "16", "Blueberry", "#4986E7", "#3F51B5" ],
  [ "17", "Lavender", "#9A9CFF", "#7986CB" ],
  [ "18", "Wisteria", "#B99AFF", "#B39DDB" ],
  [ "19", "Graphite", "#C2C2C2", "#616161" ],
  [ "20", "Birch", "#CABDBF", "#A79B8E" ],
  [ "21", "Radicchio", "#CCA6AC", "#AD1457" ],
  [ "22", "Cherry Blossom", "#F691B2", "#D81B60" ],
  [ "23", "Grape", "#CD74E6", "#8E24AA" ],
  [ "24", "Amethyst", "#A47AE2", "#9E69AF" ],
];

// Map from classic to modern event and calendar colors. The two sets use different colors,
// even for the same name (e.g., Banana classic is #FBD75B for events and #FAD165 for calendars).
const EVENT_CLASSIC_TO_MODERN = new Map(EVENT_COLORS.map(
    ([, , classic, modern]) => [classic.toUpperCase(), modern]));
const CALENDAR_CLASSIC_TO_MODERN = new Map(CALENDAR_COLORS.map(
    ([, , classic, modern]) => [classic.toUpperCase(), modern]));

/**
 * The API returns "classic" colors, but the Google Calendar UI shows "modern" colors.
 * Map between the two if we can, otherwise return the classic one.
 * @param classicColor the background color returned by the API for an event, including the "#".
 */
export function getEventModernColor(classicColor: string): string {
  return EVENT_CLASSIC_TO_MODERN.get(classicColor.toUpperCase()) ?? classicColor;
}

/**
 * The API returns "classic" colors, but the Google Calendar UI shows "modern" colors.
 * Map between the two if we can, otherwise return the classic one.
 * @param classicColor the background color returned by the API for a calendar, including the "#".
 */
export function getCalendarModernColor(classicColor: string): string {
  return CALENDAR_CLASSIC_TO_MODERN.get(classicColor.toUpperCase()) ?? classicColor;
}
