import {Calendar} from "./Calendar";
import {compareDayjs} from "./utils";
import {TimeSpan} from "./TimeSpan";

export class Event {
  public readonly gevent: gapi.client.calendar.Event;
  public readonly calendar: Calendar;
  public readonly isFullDay: boolean;
  public readonly timeSpan: TimeSpan;

  constructor(gevent: gapi.client.calendar.Event, calendar: Calendar,
                        isFullDay: boolean, timeSpan: TimeSpan) {

    this.gevent = gevent;
    this.calendar = calendar;
    this.isFullDay = isFullDay;
    this.timeSpan = timeSpan;
  }

  /**
   * Return negative, zero, or positive for sorting this event and the other one by start time.
   */
  public compareTo(o: Event): number {
    // Compare by start time.
    let cmp = compareDayjs(this.timeSpan.startTime, o.timeSpan.startTime);
    if (cmp !== 0) {
      return cmp;
    }

    // Break ties with the event ID.
    cmp = this.gevent.id.localeCompare(o.gevent.id);
    if (cmp !== 0) {
      return cmp;
    }

    // But even that can be the same when the same event is on multiple calendars, so compare
    // the calendar ID.
    return this.calendar.gcal.id.localeCompare(o.calendar.gcal.id);
  }
}
