import {Calendar} from "./Calendar";
import dayjs from "dayjs";
import {Day} from "./Day";
import DayView from "./DayView";
import {DAY_HEIGHT, END_HEIGHT, getDayString, range} from "./utils";
import "./CalendarView.css";
import MultiDayEventView from "./MultiDayEventView";
import MonthView from "./MonthView";
import {MultiDayEvent} from "./MultiDayEvent";
import {EventCluster} from "./EventCluster";

export interface CalendarViewProps {
  calendars: Calendar[];
  minTime: dayjs.Dayjs;
  maxTime: dayjs.Dayjs;
}

function CalendarView(props: CalendarViewProps) {
  const before = Date.now();
  const days: Day[] = [];
  // Map from "YYYY-MM-DD" (getDayString()) format to Day object.
  const dayMap = new Map<string,Day>();
  const multiDayEvents: MultiDayEvent[] = [];

  // Create the days.
  for (let dayTime = props.minTime; dayTime.isBefore(props.maxTime); dayTime = dayTime.add(1, "day")) {
    const day = new Day(dayTime);
    days.push(day);
    dayMap.set(day.key, day);
  }

  // Fill in the days with non-multi-day events.
  for (const calendar of props.calendars) {
    if (calendar.visible) {
      for (const event of calendar.events) {
        if (event instanceof MultiDayEvent) {
          multiDayEvents.push(event);
        } else {
          const day = dayMap.get(getDayString(event.timeSpan.startTime));
          if (day !== undefined) {
            day.events.push(event);
          }
        }
      }
    }
  }

  // Sort the events within the days.
  for (const day of days) {
    day.events.sort((a, b) => a.compareTo(b));
  }

  // Sort the multi-day events.
  multiDayEvents.sort((a, b) => a.compareTo(b));
  // Put multi-day events into slots.
  for (const event of multiDayEvents) {
    const startDay = dayMap.get(getDayString(event.timeSpan.startTime));
    if (startDay !== undefined) {
      const slot = startDay.allocateSlot(event);
      event.slot = slot;
      for (const time of event.timeSpan.days()) {
        const eventDay = dayMap.get(getDayString(time));
        if (eventDay !== undefined) {
          eventDay.setSlot(slot, event);
        }
      }
    }
  }
  // Put multi-day events into clusters.
  const clusters: EventCluster[] = [];
  for (const event of multiDayEvents) {
    if (clusters.length === 0 || !clusters[clusters.length - 1].contains(event)) {
      clusters.push(new EventCluster(event));
    } else {
      clusters[clusters.length - 1].add(event);
    }
  }

  // Find top/bottom pixel of each multi-day event.
  for (const event of multiDayEvents) {
    event.topY = event.timeSpan.startTime.dayOfYear() * DAY_HEIGHT + END_HEIGHT/2;
    event.bottomY = event.timeSpan.endTime.dayOfYear() * DAY_HEIGHT - END_HEIGHT/2;
  }

  // Find possible pixel positions for multi-day events.
  for (const event of multiDayEvents) {
    const topY = event.topY + END_HEIGHT/2;
    const bottomY = event.bottomY - END_HEIGHT/2;
    const midY = (topY + bottomY) / 2;
    event.possibleLabelY.splice(0, event.possibleLabelY.length);
    event.possibleLabelY.push(topY);
    event.possibleLabelY.push((topY + midY) / 2);
    event.possibleLabelY.push(midY);
    event.possibleLabelY.push((midY + bottomY) / 2);
    event.possibleLabelY.push(bottomY);
  }

  // Try every combination of positions for each cluster.
  for (const cluster of clusters) {
    let bestScore = Infinity;
    let bestYs: (number[] | undefined) = undefined;
    for (const positions of cluster.generateYs()) {
      const score = cluster.scoreYs(positions);
      if (score < bestScore) {
        bestScore = score;
        bestYs = positions;
      }
    }
    if (bestYs !== undefined) {
      cluster.assignYs(bestYs);
    }
  }
  const after = Date.now();
  console.log("Layout time", after - before, "ms");

  // Generate the months.
  const year = props.minTime.year();
  const months = range(12).map(i => dayjs(new Date(year, i)));

  return <div className="CalendarView">
    { months.map(month => <MonthView key={`month-${month.month()}`} month={month}/>) }
    { multiDayEvents.map(event => <MultiDayEventView key={event.gevent.id} event={event}/>) }
    { days.map(day => <DayView key={getDayString(day.date)} day={day}/>) }
  </div>;
}

export default CalendarView;
