import "./MultiDayEventView.css";
import {MultiDayEvent} from "./MultiDayEvent";

// Number of pixels each brace shifts over.
const BRACE_SHIFT = 20;

export interface MultiDayEventViewProps {
  event: MultiDayEvent;
}

function MultiDayEventView(props: MultiDayEventViewProps) {
  // Styles for positioning the label.
  const aboveStyle = {
    flexGrow: props.event.labelPosition,
  };
  const belowStyle = {
    flexGrow: 1 - props.event.labelPosition,
  };

  return <div
      className="MultiDayEventView"
      style={{
        gridColumn: 2,
        gridRowStart: props.event.timeSpan.startTime.dayOfYear(),
        gridRowEnd: props.event.timeSpan.endTime.dayOfYear(),
        paddingRight: (props.event.slot * BRACE_SHIFT) + "px",
      }}>
    <div className="MultiDayEventView-eventArea">
      <div className="MultiDayEventView-top"></div>
      <div className="MultiDayEventView-aboveStretch" style={aboveStyle}></div>
      <div className="MultiDayEventView-middle">
        <a className="MultiDayEventView-event"
           href={props.event.gevent.htmlLink}
           target="_blank"
           rel="noreferrer"
           style={{
             ...props.event.calendar.getStyle(),
             marginRight: (props.event.labelOffset * BRACE_SHIFT) + "px",
           }}>{props.event.gevent.summary}</a>
      </div>
      <div className="MultiDayEventView-belowStretch" style={belowStyle}></div>
      <div className="MultiDayEventView-bottom"></div>
    </div>
    <div className="MultiDayEventView-brace" style={{
        filter: props.event.calendar.cssFilter + " opacity(20%)",
    }}>
      <div className="MultiDayEventView-top"></div>
      <div className="MultiDayEventView-aboveStretch" style={aboveStyle}></div>
      <div className="MultiDayEventView-middle"></div>
      <div className="MultiDayEventView-belowStretch" style={belowStyle}></div>
      <div className="MultiDayEventView-bottom"></div>
    </div>
  </div>;
}

export default MultiDayEventView;
