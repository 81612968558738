import dayjs from "dayjs";

// Empirical measurements, okay if they're approximate:
export const DAY_HEIGHT = 22; // Height of a day in pixels.
export const END_HEIGHT = 15; // See MultiDayEventView-top in CSS.
export const LABEL_HEIGHT = 24; // Height of a label in pixels.

export function getDayString(date: dayjs.Dayjs): string {
  return date.format("YYYY-MM-DD");
}

/**
 * Clamps the date to the given range.
 */
export function clampDay(d: dayjs.Dayjs, minTime: dayjs.Dayjs, maxTime: dayjs.Dayjs): dayjs.Dayjs {
    d = dayjs.max(d, minTime) as dayjs.Dayjs;
    d = dayjs.min(d, maxTime) as dayjs.Dayjs;

    return d;
}

/**
 * Return -1, 0, or 1 to sort a and b.
 */
export function compareDayjs(a: dayjs.Dayjs, b: dayjs.Dayjs): number {
  if (a.isBefore(b)) {
    return -1;
  } else if (a.isAfter(b)) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Make a new array with entries 0 to n-1.
 */
export function range(n: number): number[] {
  const a = new Array(n);

  for (let i = 0; i < n; i++) {
    a[i] = i;
  }

  return a;
}
