import dayjs from "dayjs";
import "./MonthView.css";
import classNames from "classnames";

export interface MonthViewProps {
  month: dayjs.Dayjs;
}

function MonthView(props: MonthViewProps) {
  return <div className={classNames({
    MonthView: true,
    "MonthView-even": ((props.month.month() + 1) % 2 === 0),
    "MonthView-odd": ((props.month.month() + 1) % 2 !== 0),
  })} style={{
    gridColumn: 1,
    gridRowStart: props.month.dayOfYear(),
    gridRowEnd: props.month.month() === 11
        ? props.month.dayOfYear() + 31
        : props.month.add(1, "month").dayOfYear(),
  }}>
    <span>{props.month.format("MMMM")}</span>
  </div>;
}

export default MonthView;
