import React, {useState} from 'react';
import './App.css';
import {GoogleApiAuthenticator, GoogleApisStatus} from "./GoogleApiAuthenticator";
import {Calendar} from "./Calendar";
import CalendarView from "./CalendarView";
import dayjs from "dayjs";
import Header from './Header';
import LoadingView from "./LoadingView";

const now = dayjs();
const minTime = now.startOf("year"); // Inclusive.
const maxTime = minTime.add(1, "year"); // Exclusive.

const CALENDAR_VISIBLE_KEY = "calendar-view-calendar-visible";

// Make map from calendar ID to visibility.
const INITIAL_VISIBILITY = new Map<string,boolean>(
    JSON.parse(window.localStorage.getItem(CALENDAR_VISIBLE_KEY) ?? "[]"));

async function fetchCalendars(): Promise<Calendar[] | undefined> {
  let response: gapi.client.HttpRequestFulfilled<gapi.client.calendar.CalendarList>;

  try {
    const request = {};
    response = await gapi.client.calendar.calendarList.list(request);
  } catch (err: any) {
    console.log("Error fetching calendars", err.result.error.message);
    return undefined;
  }

  return response.result.items.map(gcal => {
    const calendar = new Calendar(gcal);
    calendar.visible = INITIAL_VISIBILITY.get(calendar.gcal.id) ?? true;
    return calendar;
  });
}

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<GoogleApisStatus>(GoogleApisStatus.INITIALIZING);
  const [authenticator, ] = useState<GoogleApiAuthenticator>(() => {
    class CalendarApiAuthenticator extends GoogleApiAuthenticator {
      onStatusChange(status: GoogleApisStatus): void {
        setStatus(status);

        switch (status) {
          case GoogleApisStatus.INITIALIZING:
            break;

          case GoogleApisStatus.INITIALIZED:
            refreshCalendars();
            break;

          case GoogleApisStatus.AUTHORIZED:
            refreshCalendars();
            break;
        }
      }
    }

    const authenticator = new CalendarApiAuthenticator();
    authenticator.initialize();
    return authenticator;
  });
  const [calendars, setCalendars] = useState<Calendar[]>([]);

  async function refreshCalendars() {
    setLoading(true);
    const calendars = await fetchCalendars();
    if (calendars === undefined) {
      setStatus(GoogleApisStatus.INITIALIZED);
      return;
    }

    const promises: Promise<boolean>[] = [];
    for (const calendar of calendars) {
      promises.push(calendar.fetchEvents(minTime, maxTime));
    }
    // Wait until all events are fetched.
    const successes = await Promise.all(promises);
    if (successes.indexOf(false) >= 0) {
      setStatus(GoogleApisStatus.INITIALIZED);
      return;
    }
    setCalendars(calendars);
    setLoading(false);
    // Scroll to today.
    window.setTimeout(() => {
      document.querySelector(".DayView-today")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 0);
  }

  function persistAndSetCalendars(updater: ((oldCalendars: Calendar[]) => Calendar[])) {
    setCalendars(oldCalendars => {
      const newCalendars = updater(oldCalendars);
      window.localStorage.setItem(CALENDAR_VISIBLE_KEY, JSON.stringify(
          newCalendars.map(calendar => [calendar.gcal.id, calendar.visible])));
      return newCalendars;
    })
  }

  return (
    <div className="App">
      <Header status={status} authenticator={authenticator} calendars={calendars} persistAndSetCalendars={persistAndSetCalendars}/>
      <div className="App-scrollContainer">
        { status === GoogleApisStatus.AUTHORIZED && loading
            ? <LoadingView/>
            : <CalendarView calendars={calendars} minTime={minTime} maxTime={maxTime}/> }
      </div>
    </div>
  );
}

export default App;
