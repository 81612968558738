import {MultiDayEvent} from "./MultiDayEvent";
import {TimeSpan} from "./TimeSpan";
import {LABEL_HEIGHT} from "./utils";

// Various demerits for label positions.
const DISTANCE_FROM_CENTER_DEMERIT = 1;
const BRACE_OVERLAP_DEMERIT = 2;
const LABEL_OVERLAP_DEMERIT = 10;

/**
 * A set of multi-day events that mutually overlap in time.
 */
export class EventCluster {
  public readonly events: MultiDayEvent[] = [];
  public timeSpan: TimeSpan;

  constructor(event: MultiDayEvent) {
    this.events.push(event);
    this.timeSpan = event.timeSpan;
  }

  /**
   * Add an event to the cluster. Must already be contained in the cluster.
   */
  public add(event: MultiDayEvent): void {
    this.events.push(event);
    this.timeSpan = this.timeSpan.unionWith(event.timeSpan);
  }

  /**
   * Whether this event is contained in this cluster (i.e., whether it overlaps
   * any existing events).
   */
  public contains(event: MultiDayEvent): boolean {
    return this.timeSpan.overlaps(event.timeSpan);
  }

  /**
   * Generate every combination of Y positions for the events in the cluster, in order. Return
   * an array of Y positions.
   */
  public* generateYs() {
    const events = this.events;

    function* go(ys: number[]): Generator<number[], void, void> {
      const i = ys.length;

      if (i === events.length) {
        yield ys;
      } else {
        for (const y of events[i].possibleLabelY) {
          yield* go([...ys, y]);
        }
      }
    }
    yield* go([]);
  }

  /**
   * Score the array of Y positions, which are in the same order as the events. A lower score is better.
   */
  public scoreYs(ys: number[]): number {
    let score = 0;
    for (let i = 0; i < this.events.length; i++) {
      const event = this.events[i];
      const y = ys[i];

      // Demerit for interfering with another label.
      for (let j = i + 1; j < this.events.length; j++) {
        score += Math.max(0, 1 - Math.abs(y - ys[j])/LABEL_HEIGHT) * LABEL_OVERLAP_DEMERIT;
      }

      // Demerit for going through another brace.
      for (let j = 0; j < this.events.length; j++) {
        if (i !== j) {
          const otherEvent = this.events[j];
          if (otherEvent.slot > event.slot &&
              y + LABEL_HEIGHT/2 >= otherEvent.topY &&
              y - LABEL_HEIGHT/2 <= otherEvent.bottomY) {

            score += BRACE_OVERLAP_DEMERIT;
          }
        }
      }

      // Demerit for being away from center.
      const midY = (event.topY + event.bottomY) / 2;
      const halfLength = midY - event.topY;
      if (halfLength > 0) {
        score += Math.abs(midY - y) / halfLength * DISTANCE_FROM_CENTER_DEMERIT;
      }
    }
    return score;
  }

  /**
   * Given a list of Y positions for each event (in order), set the positions.
   */
  public assignYs(ys: number[]): void {
    for (let i = 0; i < this.events.length; i++) {
      const event = this.events[i];
      event.labelPosition = (ys[i] - event.topY) / (event.bottomY - event.topY);

      // Shove label over if there's a brace in between.
      event.labelOffset = 0;
      for (let j = 0; j < this.events.length; j++) {
        if (i !== j) {
          const otherEvent = this.events[j];
          if (otherEvent.slot > event.slot &&
              ys[i] + LABEL_HEIGHT*0.75 >= otherEvent.topY &&
              ys[i] - LABEL_HEIGHT*0.75 <= otherEvent.bottomY) {

            event.labelOffset = Math.max(event.labelOffset, otherEvent.slot - event.slot);
          }
        }
      }
    }
  }
}
